<template>
<!-- eslint-disable -->
<div class="grid">
    <div class="col-12">
        <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
    </div>
    <div class="col-12 p-fluid">
        <div class="card">
            <div class="grid formgrid" style="margin: 1em 0 0 0;">
                <div class="col-12 mb-5 lg:col-2 lg:mb-0">
                    <span class="p-float-label">
                        <AutoComplete v-model="selectedType" :suggestions="filteredTypes"
                            @complete="searchTypes($event)" :dropdown="true" field="" forceSelection
                            @keydown.enter="searchFilter()">
                            <template #item="slotProps">
                                <div class="country-item">
                                    <div class="ml-2">{{slotProps.item}}</div>
                                </div>
                            </template>
                        </AutoComplete>
                        <label for="dropdown">Type</label>
                    </span>
                </div>
                <div class="col-12 mb-2 lg:col-1 lg:mb-0">
                    <Button icon="pi pi-search" label="Search" @click="searchFilter()"></Button>
                </div>
                <div class="col-12 mb-2 lg:col-8 lg:mb-0">
                </div>
                <div class="col-12 mb-2 lg:col-1 lg:mb-0">
                    <Button icon="pi pi-plus" class="p-button-success" label="Add New" @click="toAddPage()"></Button>
                </div>
            </div>
            <br>

        </div>
    </div>
    <div class="col-12 p-fluid">
        <div class="card">
            <DataTable :value="filtered? filtered : lovlist" v-model:selection="selectedLov" dataKey="id"
                :paginator="true" :rows="10" v-model:filters="filters" resizableColumns="true"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,25,30]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll">
                <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"
                    :sortable="true" />
                    <Column  header="Active" style="width: 60px">
                       <template #body="slotProps">
                          <span v-if="slotProps.data.active" class="pi pi-check"  />
                          <span v-else class="pi pi-times"  />
                        </template>
                    </Column>
                <Column header="Action">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button mr-2" @click="editUser(slotProps.data)" />
                        <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button mr-2" /> -->
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</div>

</template>
<script>
/* eslint-disable */
import LovService from '../../service/LovService'
export default {
    data() {
        return {
            breadcrumbHome: {icon: 'pi pi-home', to: '/dashboard'},
		    breadcrumbItems: [
			    {label:'LOV Dynamic'},
		    ],
            lovlist: null,
            columns: [],
            typevalue: [],
            type: [],
            typeselected: [],
            selectedLov: null,
            filtered: null,
            filteredTypes: null,
            selectedType: null,
        }
    },
    
    created() {
            this.columns = [{
                    field: 'type',
                    header: 'Type'
                },
                {
                    field: 'db_instance',
                    header: 'Description'
                },
                {
                    field: 'name',
                    header: 'Name'
                },
                {
                    field: 'value',
                    header: 'Return'
                },
    
            ]
            this.getTableData();
        },
        mounted() {
            this.selectedType = 'ALL';
        },
        computed: {
            searchForm() {
                console.clear();
                if (this.selectedType === 'ALL') {
                    this.filtered = null;
                } else {
                    return this.lovlist.filter(item => {
                        return (item.type.toLowerCase().match(this.selectedType.toLowerCase()))
                    })
                }
            },
        },
        methods: {
            getTableData() {
                LovService.getAllData().then((response) => {
                    this.lovlist = response.data.data;
                    this.typevalue = [];
                    this.typevalue.push('ALL');
                    var typeTmp = null;
                    for (const i of response.data.data) {
                        if (typeTmp != JSON.stringify(i["type"])) {
                            this.typevalue.push(i["type"]);
                        }
                        typeTmp = JSON.stringify(i["type"]);
                    }
                })
            },
            searchTypes(event) {
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredTypes = [...this.typevalue];
                    } else {
                        this.filteredTypes = this.typevalue.filter((item) => {
                            return item.toLowerCase().match(event.query.toLowerCase());
                        });
                    }
                }, 250);
            },
            searchFilter() {
                this.filtered = this.searchForm;
            },
            toAddPage() {
                this.$router.push('lovdetail');
            },
            editUser(data){
                this.$router.push({name: 'lovedit', params: {name: data.name}})
        }
        }
    }
</script>